<template>
  <v-app id="atikaApp" class="h-100 w-100">
    <!--<v-row>
      <v-col class="col-lg-2 col-md-2 col-sm-0">
        <Menu></Menu>
      </v-col>
      <v-col class="col-lg-10 col-md-2 col-sm-12">
        <v-container>
            <iframe
            style="overflow:hidden;height:100%;width:100%;min-height: 1000px !important;"
            :src=marco
            scrolling="yes"
            frameborder="0">
            </iframe>
        </v-container>
      </v-col>
    </v-row>-->
    <container fluid grid-list-sm>
      <v-layout row wrap>
        <v-flex xs0 sm2 md2 xxl2>
          <Menu></Menu>
        </v-flex>
        <v-flex xs12 sm10 md10 xxl10>
          <iframe style="overflow:auto;height:100%;width:100%;min-height: 1000px !important;" :src=marco
            scrolling="yes" frameborder="0">
          </iframe>
        </v-flex>
      </v-layout>
    </container>
  </v-app>
</template>

<script>
import Menu from '@/components/Menu.vue'
export default {
  name: 'Iframe',

  components: { Menu },

  data() {
    return {
      ruta: new URL(window.location.href),
      ref: '',
      marco: ''
    }
  },

  created() {
    this.ref = this.ruta.searchParams.get('ref')
    // let idUsuario=this.ruta.searchParams.get('idUsuario')
    this.marco = process.env.VUE_APP_VUE + this.ref
    console.log("ruta menu -> " + this.marco)
  }
}
</script>