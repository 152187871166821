<template>
    <v-navigation-drawer
      v-model="navigationDrawer"
      v-if="nombreUsuario.length>0"
          permanent 
          elevation="5"
          class="mt-15 ml-5">
                <v-img
                class="imagen_fondo"
                src="@/assets/logo.svg"

                ></v-img>
              <v-divider></v-divider>
                <v-list-item-avatar>
                  <v-icon>
                    mdi-account
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title><span class="text-lg-center">{{nombreUsuario}}</span></v-list-item-title>
                  <v-list-item-subtitle>
                      <v-btn 
                          v-on:click="cerrarSesion"
                          class="maximum_red-fondo white-texto" block>
                        Cerrar sesión
                      </v-btn>
                      
                    </v-list-item-subtitle>
                </v-list-item-content>
              <v-divider></v-divider>
              <v-list nav dense>
                  <v-list-item v-if="ruta!='Home' " to="/home">
                        <v-list-item-icon>
                          <v-icon>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            Volver al Home
                        </v-list-item-content>
                  </v-list-item>
                  <v-list-item 
                      v-for="app in dataApps" v-bind:key="app.id_app" 
                      :to=app.enlace link>
                    <v-list-item-icon>
                      <v-icon>{{app.icono}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>           
                        {{app.nombre}}
                    </v-list-item-content>
                  </v-list-item>
              </v-list>

        </v-navigation-drawer>
</template>

<script>
import axios from 'axios'
export default {
    name:'Menu', 

    data() {
        return {
            navigationDrawer:false,
            nombreUsuario:"",
            dataApps:'',
            ruta:'',
            idUsuario:this.$cookies.get("idUsuario")

        }
    }, 

    created() {
      console.log("menu")
    this.autentificaUsuario()
        if(!(this.$route.name=='Login' && $this.$route.name=='/'))
    {
      this.navigationDrawer=true
    }
    this.ruta= this.$route.name;
    
    },

    methods: {
        //Primero que todo, validar que el usuario exista
     autentificaUsuario:function() {
      if (!(this.$cookies.isKey("nombreUsuario"))) {
          this.$router.push("/");
        } else {
          if (this.$cookies.isKey("nombreUsuario"))
            {
              this.nombreUsuario=this.$cookies.get("nombreUsuario")
            } else  {
              this.nombreUsuario=this.usuarioLogin
            }
          this.navigationDrawer=true
          this.obtieneApps()
        }
    }, 

        cerrarSesion:function() {
        this.appBar=false
        this.navigationDrawer=false
        this.$cookies.remove("nombreUsuario")
        this.$cookies.remove("idUsuario")
        this.$router.push("/")
        },

        normalizaEnlaces:function(sabanaApp)
        {
          console.log("normalizaEnlaces")
          console.log(sabanaApp)
          console.log("Valor antes de foreach -> " + this.idUsuario)
          sabanaApp.forEach( app  =>  {
            if (app.iframe==1)
              {
                console.log("Valor -> " + this.idUsuario)
                let enlace= app.id_app + "?ref=" + app.enlace + "?idUsuario=" + this.idUsuario
                console.log("Enlace -> " + enlace)
                app.enlace=enlace
              }
          })
          return sabanaApp
        },

        obtieneApps:function() {
          var ruta=process.env.VUE_APP_API_USUARIOS_OBTENERAPLICACIONES + "/"  + this.idUsuario
          console.log("Ruta -> " + ruta)
          axios.get(ruta).then(response => {
            //this.dataApps=response.data;
            this.dataApps=this.normalizaEnlaces(response.data)
            console.log("dataApps")
            console.log(this.dataApps)
          })
        }
    }
}
</script>

