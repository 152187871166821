<template>
    <div class="app-header gradiente">
        <v-app-bar
                class="cabecera gradiente"
                dense
                >
            <template v-slot:img="{ props }">
            <v-img
            v-bind="props"
            class="imagen_fondo"
            src="@/assets/logo.svg"

            ></v-img>
        </template>
            <v-app-bar-title class="jet-texto texto_derecha">
                        Cálculo de precios
            </v-app-bar-title>
        </v-app-bar>
    </div>
</template>
<style>
.cabecera {
    height:50px !important;
    margin-top:15px !important;
}

.gradiente{
    background: rgba(255,255,255,1);
    background: -moz-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 58%, rgba(214,35,36,1) 100%);
    background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(255,255,255,1)), color-stop(58%, rgba(255,255,255,1)), color-stop(100%, rgba(214,35,36,1)));
    background: -webkit-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 58%, rgba(214,35,36,1) 100%);
    background: -o-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 58%, rgba(214,35,36,1) 100%);
    background: -ms-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 58%, rgba(214,35,36,1) 100%);
    background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 58%, rgba(214,35,36,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d62324', GradientType=1 );
}

.imagen_fondo {
    height:30px;
    width:120px;
    margin-left:15px;
    margin-bottom:15px;
}

.texto_derecha {
    margin-left:45%;
}
</style>
<script>
export default {
    name:'HeaderLogin'
}
</script>