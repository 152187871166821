<template>
    <v-app class="fondo-aplicacion">
        <v-row>
            <v-col class="col-lg-2">
                <Menu></Menu>
            </v-col>
            <v-col class="col-lg-10">
                <v-card elevation="5" max-width="1000px">
        <v-card-title>Administrador de aplicaciones</v-card-title>
        <v-card-text>

            <v-container fluid class="white-fondo">
                <v-tabs
                    v-model="tab"
                    >
                    <v-tabs-slider class="old_rose-fondo"></v-tabs-slider>
                    <v-tab key="app" href="#apps" class="jet-texto">
                        Administración de aplicaciones
                    </v-tab>

                    <v-tab key="permisos" href="#permisos" class="jet-texto">
                        Administrar permisos a aplicaciones
                    </v-tab>             
                </v-tabs>

               
                <template>
                    <v-tabs-items v-model="tab">
                        <v-tab-item id="apps" value="apps">
                            <Aplicaciones></Aplicaciones>
                        </v-tab-item>
                        <v-tab-item id="permisos" value="permisos">
                            <Permisos></Permisos>
                        </v-tab-item>
                </v-tabs-items>
                </template>
        
            </v-container>

        </v-card-text>

    </v-card>  
            </v-col>
        </v-row>
          
    </v-app>
</template>

<script>
import Aplicaciones from '@/components/aplicaciones/Aplicaciones.vue'
import Permisos from '@/components/aplicaciones/Permisos.vue'
import Menu from '@/components/Menu.vue'

    export default {
    name: 'Apps',

    components: { Aplicaciones,Permisos, Menu },

    data() {
        return {
            tab: "",
            dataApps: []
        };
    },
}
    
    </script>
