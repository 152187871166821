<template>
    <v-app>
        <h3>Ultimos registros ingresados</h3>
        <v-data-table
            :headers="cabecerasConsulta"
            :items="dataConsulta"
            :search="criterioBusqueda"
            :no-data-text=textoTablaSinDatos
            no-results-text="No hay resultados"
            hide-default-footer
            >

            <template v-slot:top>
                <v-text-field
                    v-model="criterioBusqueda"
                    label="Buscar en la tabla"
                    class="mx-4">
                </v-text-field>
            </template>
        </v-data-table>
    </v-app>
</template>

<style>
    h3 {
       margin-top:20px;
       text-align:center;
    }
   
   </style>

<script>
import axios from 'axios'
export default {
    name:'TablaConsulta',
    data() {
        return {
        textoTablaSinDatos:'Cargando datos, por favor espere...',
        cabecerasConsulta:[],
            dataConsulta:[],
            criterioBusqueda:''
        }
    }, 
    props:['criterio','cabecera','recargaTabla'], 

    created() {
        this.generaCabeceras()
        this.consultaRegistros()
    },

    watch: {
        recargaTabla(value,newValue) {
            console.log("watch")
            for(var elemento in this.dataConsulta) delete this.dataConsulta[elemento]
            this.consultaRegistros()
        }
    },

    methods: {


        generaCabeceras() {
            let dataCampos=this.criterio.split(',')
            let dataCabeceras=this.cabecera.split(',')
            let recorreCampo=0
            dataCabeceras.forEach(element => {
                let singleCabecera=  {
                    text:element, 
                    align:'start',
                    value:dataCampos[recorreCampo],
                    sortable:true
                } 
                recorreCampo++ 
                this.cabecerasConsulta.push(singleCabecera)
            })
        },

        async consultaRegistros()
        {
            await axios.post(process.env.VUE_APP_RUTA_COMEX, 
            {
               accion:'consultaCampos',
               criterio:this.criterio 
            }).then(response => {
                console.log("valores registros")
                console.log(this.cabecerasConsulta)
                console.log(response.data);
                this.dataConsulta=response.data
                this.textoTablaSinDatos="No hay datos ingresados"
            })
        }
    }
}
</script>
