<template>
    <v-app>
        <v-card 
            width="250" 
            class="widget"
            elevation="10">
            <v-card-title>
                <v-icon  
                    class="iconoSuperior"
                    elevation="6"
                    medium>
                    mdi-calendar-clock-outline
                </v-icon>
                <span class="texto-titulo-widget">{{dia}}</span>
            </v-card-title>
            <v-card-text>
                <span>{{fecha}}</span>
            </v-card-text>
        </v-card>
    </v-app>
</template> 

<script>
export default {
    name:'Fecha',
    
    data() {
        return{
            dia:'',
            fecha:''
        }
    },

    created() {
        this.obtenerFecha()
    },

    methods: {
        obtenerFecha: function() {
            let fechaSistema= new Date()
            const meses = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre']
            const dias = ['ninguno','lunes','martes','miercoles','jueves','viernes','sabado','domingo']
            this.dia=dias[fechaSistema.getDay()]
            this.fecha=fechaSistema.getDate() + " de "
                        + meses[fechaSistema.getMonth()] + " de "
                        + fechaSistema.getFullYear() 
        }
    }
}
</script>