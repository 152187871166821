<template>
    <v-app>
        <v-container fluid>
            <v-window v-model="pasoCargaArchivo">
                <v-window-item :value="1">
                    <cargaArchivo @resultado="avanzar"></cargaArchivo>
                </v-window-item>
                <v-window-item :value="2">

                    <revisionArchivo :resultadoExcel="excelCargado"
                        @clickAnterior="pasoAnterior"
                        @clickSiguiente="pasoSiguiente"></revisionArchivo>

                </v-window-item>
                <v-window-item :value="3">
                    <confirmacionCarga
                        @clickAnterior="pasoAnterior"
                        ></confirmacionCarga>
                </v-window-item>
            </v-window>

        </v-container>
    </v-app>
</template>

<script>
import cargaArchivo from '@/components/producto/cargaProducto/cargaArchivo.vue'
import revisionArchivo from '@/components/producto/cargaProducto/revisionArchivo.vue'
import confirmacionCarga from '@/components/producto/cargaProducto/confirmacionCarga.vue'

export default {
    name:'ActualizarArchivo',

    components : {
        cargaArchivo,
        revisionArchivo,
        confirmacionCarga
    },

    data() {
        return {
            pasoCargaArchivo:1,
            excelCargado:{}
        }
    },

    methods : {
       avanzar(resultado) {
            this.excelCargado=resultado
            console.log("Resultado -> " + this.excelCargado)
            this.pasoSiguiente()
        },

        pasoAnterior() {
            this.pasoCargaArchivo--
        },

        pasoSiguiente() {
            this.pasoCargaArchivo++
        }
    }
}
</script>