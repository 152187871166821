<template>
    <v-app>
        <v-card>
            <v-card-title class="xanadu-fondo white-texto">
                <span class="texto_centrado">Seguro de importación</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <fecha  campo="seguro_importacion"
                            @registraFecha="registraFecha">
                        </fecha>
                    </v-col>
                    <v-col>
                        <Porcentaje @Porcentaje="registraPorcentaje"></Porcentaje>
                    </v-col>
                </v-row>
                <span class="maximum_red-texto" v-show="validaFecha">La fecha no puede ir vacía</span>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    @click="btnAceptar"
                    class="maximum_red-fondo white-texto">
                    Aceptar
                </v-btn>
                <v-btn
                    @click="btnCancelar"
                    class="old_rose-fondo space-cadet-texto">
                    Cancelar
                </v-btn>

            </v-card-actions>
        </v-card>
        <TablaConsulta
            :recargaTabla="recargaTabla"
            cabecera="Fecha,Porcentaje"
            criterio="seguro_importacion_fecha,seguro_importacion_porcentaje">
        </TablaConsulta>
    </v-app>
</template>


<script>
import Fecha from '@/components/comex/Elementos/Fecha.vue'
import Porcentaje from './Elementos/Porcentaje.vue'
import TablaConsulta from '@/components/comex/Elementos/TablaConsulta.vue'
export default {
    name:'AdHonorem',

    components:{ Fecha, Porcentaje, TablaConsulta },

    props:['modo'],

    data() {
        return {
            validaFecha:false,
            recargaTabla:0,
            objSeguroImportacion: {
                fecha:'',
                porcentaje:0
            }
        }
    },

    methods: {
        btnAceptar: function() {
            if (this.objSeguroImportacion.fecha.length==0) {
                this.validaFecha=true
            } else {
                this.recargaTabla++;
                this.$emit('objSeguroImportacion',this.objSeguroImportacion)
            }

        },

        btnCancelar: function() {
            this.validaFecha=false
            this.$emit('objSeguroImportacion',null)
                    },

         registraFecha(valor)
        {
            this.objSeguroImportacion.fecha=valor
        },
          registraPorcentaje(valor)
        {
            this.objSeguroImportacion.porcentaje=valor
        }
    }
}
</script>