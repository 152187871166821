<template>
    <div class="white-fondo">

        <v-btn class="old_rose-fondo white-texto mt-5" v-on:click="abrirFormulario">
            Agregar nueva aplicación
        </v-btn>

       
        <v-card>
            <v-card-title>
                Aplicaciones
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="cabeceraAplicaciones"
                    :items="dataAplicaciones"
                    hide-default-footer
                >

                <template v-slot:item.icono="{item}">
                    <v-icon class="maximum_red-texto">
                        {{item.icono}}
                    </v-icon>    
                </template>

                <template v-slot:item.acciones="{item}" >
                    <v-btn class="maximum_red-fondo white-texto mr-5" v-on:click="editarApp(item)">
                        Editar
                    </v-btn>
                    
                    <v-btn class="old_rose-fondo white-texto" v-on:click="borrarApp(item)">
                        Eliminar
                    </v-btn>
                </template>

                </v-data-table>
            </v-card-text>
           
        </v-card>

       
      
        <v-dialog v-model="formAplicacion" width="400">
            <v-card>
                <v-card-title>
                    Registrar nueva aplicación
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-text-field
                            v-model="objAplicacion.nombre"
                            label="Nombre de la aplicación"
                            required>
                        </v-text-field>
                        <v-text-field
                            v-model="objAplicacion.enlace"
                            label="Ruta de la aplicación"
                            required>
                        </v-text-field>
                        <v-text-field 
                            v-model="objAplicacion.icono"
                            label="Icono de la aplicación"
                            required>
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="maximum_red-fondo white-texto" v-on:click="ejecutaFormulario">
                        Registrar aplicación
                    </v-btn>
                    <v-btn class="old_rose-fondo white-texto" v-on:click="formAplicacion=false">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
   
    </div>
</template>


<script>
    import axios from 'axios'

    export default({
        component:'Aplicaciones', 

        data() {
        return {
            objAplicacion: {
                id_app: "",
                nombre: "",
                enlace:"",
                icono:"",
            },
            cabeceraAplicaciones: [
                {
                    text:'Nombre aplicacion',
                    value:'nombre',
                },
                {
                    text:'Nombre componente',
                    value:'enlace',
                },
                {
                    text:'Icono aplicación',
                    value:'icono'
                }, 
                {
                    text:'Acciones',
                    value:'acciones'
                }
            ],
            dataAplicaciones:[],
            snackbarApp: false,
            snackAppTexto: "",
            formAplicacion:false,
            textoTituloFormulario:''       
        }
        },
        
        created() {
            this.mostrarAplicaciones()
        },

        methods: {
            mostrarAplicaciones: function() {
                console.log("Ruta -> " + process.env.VUE_APP_API_APP_LISTAR)
                axios.get(process.env.VUE_APP_API_APP_LISTAR).then(response => {
                    this.dataAplicaciones=response.data
                    console.log(response.data)
                })
            }, 

            abrirFormulario:function() {
                this.textoTituloFormulario="Registrar nueva aplicación"
                this.formAplicacion=true
            },

            ejecutaFormulario:function() {
                this.formAplicacion=false
                if(this.objAplicacion.id_app>0)
                {
                    console.log("edicion")
                    var ruta=process.env.VUE_APP_API_APP_ACTUALIZAR+"/"
                    +this.objAplicacion.nombre + "/"
                    +this.objAplicacion.enlace + "/"
                    +this.objAplicacion.icono + "/"
                    +0 + "/"
                    +this.objAplicacion.id_app
                } else 
                {
                    console.log("Agregar")
                    var ruta=process.env.VUE_APP_API_APP_AGREGAR+"/"
                    +this.objAplicacion.nombre + "/"
                    +this.objAplicacion.enlace + "/"
                    +this.objAplicacion.icono + "/"
                    +"0"
                }
                
                console.log("Ruta app -> " + ruta)
                axios.get(ruta).then(response => {
                    console.log("respuesta axios")
                    console.log(response.data)
                    this.mostrarAplicaciones()
                })
            }, 

            editarApp: function(item) {
                let ruta =process.env.VUE_APP_API_APP_CONSULTAR + "/"
                +item.id_app
                axios.get(ruta).then(response => {
                    this.objAplicacion=response.data
                    console.log(this.objAplicacion)
                    this.formAplicacion=true
                })
            },

            borrarApp:function(item) {
                let ruta =process.env.VUE_APP_API_APP_ELIMINAR+"/"
                +item.id_app
               axios.get(ruta).then(response => {

                    if (response.data.respuesta=="no eliminada") 
                    {
                        console.log("Error. No se puede borrar una aplicación que tiene usuarios asociados.")
                    } else 
                    {
                        console.log("Aplicación eliminada.")
                    }
                    this.mostrarAplicaciones()
               })
            }
        }
                
    })
</script>