<template>
    <div>
        <v-container>
            <h3>
                1. Suba un archivo excel aquí :
            </h3>
            <v-file-input
                v-model="excel"
                ref="excel"
                show-size
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                label="Ingresar archivo excel">
            </v-file-input>
            <p>Puede cargar una muestra de documento desde <a class="maximum_red-texto" href="Ejemplo-Excel.xlsx">aquí.</a></p>
            <v-btn
                color="maximum_red-fondo darken-4 white--text"
                :disabled="btnCarga"
                v-on:click="subeArchivos">
                Cargar archivo
            </v-btn>
            <v-btn
                color="old_rose-fondo black-texto"
                v-on:click="volverHome"
                >
                Volver al Home
            </v-btn>
        </v-container>
<br />
<hr />
        <v-container fluid>
            <h2 class="texto_centrado">Listas cargadas anteriormente</h2>
            <TablaListas></TablaListas>
        </v-container>
    <v-snackbar timeout=0 v-model="snackbar">
        Momento por favor, cargando archivo..
    </v-snackbar>

    <v-dialog v-model="problemasArchivo" width="350">
        <v-card>
            <v-card-title>Archivo no reconocido</v-card-title>
            <v-card-text>Tenemos problemas para leer este archivo. Por favor intente cambiando el formato del archivo o copiandolo con otro nombre,</v-card-text>
            <v-card-actions>
                <v-btn class="maximum_red-fondo white-texto" block v-on:click="problemasArchivo=false;volverHome()">
                    Cerrar ventana
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="problemasApp" width="450">
        <v-card>
            <v-card-title>Problemas para subir el archivo</v-card-title>
            <v-card-text>
                Lo sentimos. Tuvimos un problema para subir el archivo. Por favor, intente nuevamente. Si el problema sigue, comúniquese con mesa de ayuda informática.  
            
            </v-card-text>
            <v-card-actions>
                <v-btn class="maximum_red-fondo white-texto" block v-on:click="cerrarApp()">
                    Cerrar ventana
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import TablaListas from '@/components/producto/TablaListas'
    export default {
        name:'cargaArchivo',
        components:{ TablaListas, TablaListas },
        data() {
            return {
                problemasArchivo:false,
                problemasApp:false,
                errorApp:'',
                excel:null,
                btnCarga:true,
                snackbar:false,
                respuesta:{
                    excel:[],
                    no_encontrados:[],
                    datos_exitosos:0,
                    datos_defectuosos:0
                    }
                }
        },


        watch: {
            excel() {
                if (typeof this.excel === 'object')
                {
                    this.btnCarga=false
                }
            }
        },

        methods: {



           async subeArchivos() {
                this.btnCarga=false
                this.snackbar=true
                await this.cargarArchivo().then( () => {
                        this.snackbar=false
                        this.$emit('resultado', this.respuesta)
                })
            },

           async cargarArchivo() {

                if (this.excel) {
                    let formData = new FormData()
                    formData.append("excel",this.excel)
                    try {
                        await  axios.post(process.env.VUE_APP_RUTA_PRODUCTO,formData).then(response => {
                           
                                if (response.data || response.data.length > 0 )
                                    {
                                        /*
                                        console.log("tipo archivo")
                                        console.log(typeof(response.data))
                                        console.log(response.data)
                                        */
                                       // this.respuesta=Object.entries(response.data)
                                       this.respuesta=response.data
                                        /*
                                        let respuestaArray=Object.entries(response.data)
                                        console.log("respuesta Array")
                                        console.log(respuestaArray)
                                        */
                                       // console.log(JSON.parse(response.data))

                                     //  this.respuesta=JSON.parse(respuestaArray.slice(2))
                                     //   console.log("Respuesta enviada ")
                                       // console.log(response.data)
                                     //   console.log(this.respuesta)
                                    } else {
                                        console.log("archivo no valido")
                                        this.problemasArchivo=true
                                    }
                                })

                    } catch(error) {
                        this.respuesta = null
                        console.log("Error de axios")
                        console.log("Mensaje -> " + error.message)
                        console.log(error)
                        this.problemasApp=true
                        this.errorApp=error.message
                    }
                }
                this.$emit('siguiente',1)
            },

            volverHome() {
                this.$router.push('/home')
            },

            cerrarApp()
            {
                this.problemasApp=FontFaceSetLoadEvent
                this.volverHome()
            }
        }
    }
</script>