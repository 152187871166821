<template>
    <v-app>
        <h1>Bienvenido al formulario de tickets de mesa de ayuda</h1>
        <hr />
        <br />
        <v-card elevation="5" max-width="1000px" class="mt-15">
                    
                    <v-card-text>
                        <v-container class="pa-5">
                            Utilice esta aplicación para comunicarse y solucionar problemas con el área de informática o de servicios. 
                            <br />
                            <br />
                            <br />
                        <v-row>
                            <v-col class="col-lg-6">
                                <span>Nombre solicitante : </span>
                            </v-col>
                            <v-col clasS="col-lg-6">
                                <strong>{{objTicket.nombreUsuario}}</strong>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-lg-6">
                                <span>Correo de respuesta : </span>
                            </v-col>
                            <v-col clasS="col-lg-6">
                                <strong>{{objTicket.correoUsuario}}</strong>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-lg-6">
                                ¿A quien va dirigido la solicitud? 
                            </v-col>
                            <v-col class="col-lg-6">
                                <v-radio-group
                                    v-model="objTicket.departamento"
                                    column>
                                    <v-radio
                                        label="Unidad de informática"
                                        value="informatica"
                                        color="maximum_red-fondo">
                                    </v-radio>
                                    <v-radio
                                        label="Unidad de servicios generales"
                                        value="servicios"
                                        color="maximum-red-texto">
                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-lg-12">
                                <v-text-field
                                    outlined
                                    v-model="objTicket.titulo"
                                    label="Titulo o resumen de la solicitud">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-lg-12">
                                <v-textarea
                                    outlined
                                    v-model="objTicket.descripcion"
                                    label="Describa su problema. Trate de explicarlo con el mayor detalle posible."
                                    >
                                </v-textarea>
                                    
                            </v-col>
                        </v-row>
                    </v-container>   
                    
                    </v-card-text>
                    <v-card-actions>
                        <v-btn 
                        class="maximum_red-fondo white--text"
                        block
                        v-on:click="subirTicket"
                        :disabled="btnEnviar"
                        >
                            Enviar ticket
                        </v-btn>
                    </v-card-actions>

                </v-card> 

                <v-dialog
            width="300"
            v-model="mostrarEnvio"
            persistent
            >
            <v-card>
                <v-card-title>
                    <h3 class="jet-texto">
                        Enviando petición
                    </h3>
                </v-card-title>
                <v-card-text>
                    <v-container class="text-center">
                        Espere un momento... estamos ingresando su ticket. 
                        <br />
                        <br />
                        <v-img width="50" class="text--center ml-15" src="@/assets/loading-nuevo.gif"></v-img>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="mostrarAviso"
            width="500"
            >
            <v-card>
                <v-card-title>
                    <h3 class="jet-texto">
                        Solicitud recibida exitosamente
                    </h3>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        Hemos recibido su solicitud. Nos pondremos en contacto a la brevedad con usted para resolver el problema. 
                        <br />
                        <strong class="jet-texto">Su numero de ticket es el : {{noTicket}}</strong>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="maximum_red-fondo white-texto align-center" v-on:click="cerrarVentana">Cerrrar ventana</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-app>
</template>
<style>
</style>

<script>
import axios from 'axios'
export default ({
    name:'Subir', 

    data() {
        return {
            tab: "",
            dataApps: [],
            noTicket:'',
            btnEnviar:false,
            objTicket: {
                nombreUsuario:'',
                correoUsuario:'',
                departamento:'',
                titulo:'',
                descripcion:''
            },
            mostrarEnvio:false,
            mostrarAviso:false,
            
        }
    }, 

    created() {
        this.obtenerDatosUsuario()
    },

    methods: {

        subirTicket() {
            console.log("subir ticket")
            
            this.btnEnviar=true
            this.mostrarEnvio=true

            var objOsTicket = {
                'alert': true,
                'autorespond' : true,
                'source' : 'API',
                'name' : this.objTicket.nombreUsuario,
                'departamento':this.objTicket.departamento,
                'email': this.objTicket.correoUsuario,
                'subject' : this.objTicket.titulo,
                'message' : this.objTicket.descripcion
            }

            console.log(objOsTicket)

            const url="https://mesadeayuda.atika.cl/api.php"


            axios.post(url,
                        {data:objOsTicket}).then(response => {
                    this.mostrarEnvio=false
                    this.mostrarAviso=true
                    this.noTicket=response.data
            }).catch (error => {
                console.log("Error ")
                console.log(error)
            }) 
            
        }, 

        obtenerDatosUsuario() {
            this.objTicket.nombreUsuario=this.$cookies.get('nombreUsuario')
            this.objTicket.correoUsuario=this.$cookies.get('login') + '@atika.cl'
        }, 

        cerrarVentana() {
            this.mostrarAviso=false
        }
    }
}) 
</script>