<template>
  <div id="app" class="h-100 w-100">
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W9HNGPQ"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <v-container fluid>
     <v-row>
        <v-col>
          <v-app-bar v-model="appBar">
        
              <v-spacer></v-spacer>
              <v-toolbar-title>
                Aplicaciones Atika
              </v-toolbar-title>
          </v-app-bar>
          
        </v-col>
      </v-row>
      <v-row>
        <v-col class="lg-12">
          <router-view :model="usuarioLogin" />
        </v-col>
      </v-row>
    </v-container>
      <v-snackbar
          v-model="snackHome"
          timeout=2000
          dark
          color="red darken-4"
          block>
            <span style="text-align:center !important">
              {{snackTexto}}
            </span>
      </v-snackbar>
      <div class="home separacionSuperior">
      </div>
    </div>    
    
</template>

<style>
@import "@/assets/css/global.css";
@import "@/assets/css/colores.css";
@import "@/assets/css/fuentes.css";

</style>

<script>
export default ({
  
  data() {
    return {
     
      appBar:true,
      navigationDrawer:true,
      snackHome:false,
      snackTexto:'',
      usuarioLogin:'',
      rutaActual:''
    }
  }, 

  created() {
   
  },



  methods: {
    

   
  }
})
</script>

