<template>
    <v-app>
        <v-card>
            <v-card-title class="xanadu-fondo white-texto">
                <span class="texto_centrado">Gastos aduaneros</span>
            </v-card-title>
           <v-card-text>
                <v-row>
                    <v-col>
                        <Fecha campo="gastos_aduaneros" @registraFecha="registraFecha"></Fecha>
                    </v-col>
                     <v-col>
                        <origenMarca @OrigenMarca="registraOrigenMarca"></origenMarca>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <UnidadNegocios @UnidadNegocios="registraUnidadNegocios"></UnidadNegocios>
                    </v-col>
                   <v-col>
                        <Porcentaje @Porcentaje="registraPorcentaje"></Porcentaje>
                    </v-col>
                </v-row>
                 <span class="maximum_red-texto" v-show="validaFecha">La fecha no puede ir vacía</span>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    @click="btnAceptar"
                    class="maximum_red-fondo white-texto">
                    Aceptar
                </v-btn>
                <v-btn
                    @click="btnCancelar"
                    class="old_rose-fondo space-cadet-texto">
                    Cancelar
                </v-btn>

            </v-card-actions>
        </v-card>
        <TablaConsulta
            :recargaTabla="recargaTabla"
            cabecera="Fecha,Origen marca, Unidad de negocios, Porcentaje"
            criterio="gastos_aduaneros_fecha,gastos_aduaneros_origen_marca,gastos_aduaneros_unidad_negocios,gastos_aduaneros_porcentaje">
        </TablaConsulta>
    </v-app>
</template>
<script>
import Fecha from '@/components/comex/Elementos/Fecha.vue'
import origenMarca from '@/components/comex/Elementos/OrigenMarca.vue'
import Porcentaje from '@/components/comex/Elementos/Porcentaje.vue'
import UnidadNegocios from '@/components/comex/Elementos/UnidadNegocios.vue'
import TablaConsulta from '@/components/comex/Elementos/TablaConsulta.vue'

export default {
    name:'gastosAduaneros',

    components:{ Fecha, origenMarca, Porcentaje, UnidadNegocios, UnidadNegocios, TablaConsulta },

    data() {
        return {
            validaFecha:false,
            recargaTabla:0,
            objGastosAduaneros: {
                fecha:'',
                origen_marca:'',
                porcentaje:'',
                unidad_negocios:''
            }
        }
    },

     methods: {

        btnAceptar: function() {

            if (this.objGastosAduaneros.fecha.length==0) {
                this.validaFecha=true
            } else {
                this.recargaTabla++
                this.$emit('objGastosAduaneros', this.objGastosAduaneros)
            }

        },

        btnCancelar: function() {
            this.validaFecha=false
            this.$emit('objGastosAduaneros', null)
        },

        registraFecha(valor)
        {
            this.objGastosAduaneros.fecha=valor
        },

        registraOrigenMarca(valor)
        {
            this.objGastosAduaneros.origen_marca=valor
            console.log(this.objGastosAduaneros)
        },

        registraPorcentaje(valor)
        {
            this.objGastosAduaneros.porcentaje=valor
        },

        registraUnidadNegocios(valor)
        {
            this.objGastosAduaneros.unidad_negocios=valor
        }
    }
}
</script>