<template>
  <v-app id="atikaApp" class="h-100 w-100">
    <v-row>
      <v-col class="col-lg-2 col-md-2 col-sm-0">
     
       <Menu></Menu> 
      </v-col>
      <v-col class="col-lg-10 col-md-2 col-sm-12">
        <InformesHome></InformesHome>
      </v-col>
    </v-row>
      
  </v-app>

</template>

<style>
.separacionSuperior {
  margin-top:35px;
}
</style>

<script>
// @ is an alias to /src

import Menu from '@/components/Menu.vue'
import InformesHome from '@/components/informes/Home.vue'
export default {
  name: 'Informes',
  
  components:{Menu, InformesHome},

  data() {
    return {
     
    }
  },


  created() {
  },

  methods: {

   
    }
}
</script>
