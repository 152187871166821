<template>
    <v-app>
        <h1>Opciones de autoayuda</h1>
        <hr />
        <br />
        <p>Bienvenido: En esta sección encontrará alternativas de autoatención, para ir solucionando problemas recurrentes de acceso.</p>

        <v-row>
            <v-col>
                <v-btn class="maximum_red-fondo white-texto" v-on:click="desbloquearUsuario()">
                        Desbloquear mi usuario SAP
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="mensaje" width="450" >
            <v-card>
                <v-card-text class="pt-2">
                    <p class="jet-texto">
                        {{ aviso }}
                    </p>
                    
                    <v-progress-linear 
                        v-show=cargador 
                        height="10" 
                        striped 
                        color="maximum_red-fondo" 
                        indeterminate>
                    </v-progress-linear>    
                </v-card-text>
                <v-card-actions>
                    <v-btn v-on:click="mensaje=false" class="old_rose-fondo jet-texto ">
                        Cerrar ventana
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>
<style>
</style>

<script>
import axios from 'axios';

export default  ({
    name:'Ver', 

    data() {
        return {
            mensaje: false,
            aviso: 'prueba',
            login:this.$cookies.get('login'),
            cargador:true,
        }
    },

    methods: {

        parar(ms) {
            return new Promise(respuesta => setTimeout(respuesta,ms))
        },  

        async desbloquearUsuario() {
            this.mensaje=true
            this.aviso="Momento por favor. Desbloqueando usuario... no cierre esta ventana."
            var ruta=process.env.VUE_APP_API_AYUDA_DESBLOQUEAR_SAP + "/" + this.login
            await this.parar(2000)
            axios.get(ruta).then(response => {
                console.log("respuesta axios")
                console.log(response.data)
                    this.mensaje=false
                    switch(response.data) {
                    case 'No se encuentra usuario': default: 
                         console.log("paso")
                         this.mensaje=true
                         this.cargador=false
                         this.aviso='Hubo un problema para desbloquear su cuenta. No se encuentra el nombre de usuario SAP. Por favor, intente nuevamente o también puede levantar un ticket en la mesa de ayuda.'
                         break;
                    case 'Usuario desbloqueado':
                         this.mensaje=true
                         this.cargador=false
                         this.aviso='Su usuario ha sido desbloqueado en SAP. Por favor intente ingresar nuevamente en la plataforma.'
                         break;
                
                    }
                })
        }
    }

});

</script>