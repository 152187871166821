<template>
    <v-app class="fondo_aplicacion h-100 w-100">
        <v-row>
            <v-col class="col-lg-2 col-md-2 col-sm-0">
                <Menu></Menu> 
        </v-col>
      <v-col class="col-lg-10 col-md-2 col-sm-12">
                <v-row>
                    <v-row>
                        <v-col class="col-lg-12 col-md-12 col-sm-12 ma-5 pr-5">
                            <v-card 
                                elevation="10"
                                >
                                <v-card-title>
                                    <v-icon class="iconoSuperior mr-5">
                                        mdi-package-variant-closed
                                    </v-icon>
                                    Stock disponible
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-col class="col-lg-12 col-md-12 col-sm-12">
                        <v-card 
                            class="white-fondo jet-texto"
                            elevation="5">
                            <v-card-title>
                                Catálogo de productos
                            </v-card-title>
                            <v-card-text>
                                <v-data-table
                                    :headers="cabecerasStock"
                                    :search="itemCode"
                                    :items="dataStock"
                                    no-data-text="No existen datos"
                                    disable-pagination
                                    :footer-props="{
                                        showFirstLastage:true,
                                        firstIcon: 'mdi-arrow-collapse-left',
                                        lastIcon:'mdi-arrow-collapse-right',
                                        prevIcon:'mdi-minus',
                                        nextIcon:'mdi-plus',
                                        itemsPerPageText:'Productos a mostrar',
                                        pageText:''
                                    }"
                                    >
                                    <template v-slot:top>
                                        <v-text-field
                                            v-model="itemCode"
                                            label="Buscar por itemCode o nombre del producto"
                                            class="mx-4">
                                        </v-text-field>
                                    </template>

                                    <template v-slot:item.acciones="{item}">
                                        <v-btn class="maximum_red-fondo white-texto" v-on:click="abrirPanel(item)" elevation="2" icon>
                                            <v-icon class="maximum-red-texto">
                                                mdi-chevron-down 
                                            </v-icon>
                                        </v-btn>
                                       
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>    
                
      </v-col>
    </v-row>
      
  </v-app>
</template>
<script>
import Menu from '@/components/Menu.vue'
import axios from 'axios'

    export default {
        name:'StockDisponible', 
        components:{Menu},
        data() {
            return {
                itemCode:'',
                dataProductos:'',
                panelItem:null,
                cabecerasStock:[{
                    text:'itemCode',
                    align:'start',
                    sortable:false,
                    value:'ItemCode'
                    },
                    {
                    text:'Nombre',
                    align:'start',
                    sortable:false,
                    value:'ItemName'
                    },
                    {
                    text:'Físico',
                    align:'start',
                    sortable:false,
                    value:'Fisico'
                    },
                    {
                    text:'Apartado',
                    align:'start',
                    sortable:false,
                    value:'Apartado'
                    },
                    {
                    text:'Ordenado',
                    align:'start',
                    sortable:false,
                    value:'Ordenado'
                    },
                    {
                    text:'Embarcado',
                    align:'start',
                    sortable:false,
                    value:'Embarcado'
                    },
                    {
                    text:'Disponible inmediato',
                    align:'start',
                    sortable:false,
                    value:'DisponibleInmediato'
                    },
                    {
                    text:'Disponible total',
                    align:'start',
                    sortable:false,
                    value:'DisponibleTotal'
                    },


                ],

               
                dataStock : [],

                cabecerasStockDetallado:[{
                    text:'Variante stock',
                    align:'start',
                    sortable:false,
                    value:'variante'
                    },
                    {
                    text:'itemCode',
                    align:'start',
                    sortable:false,
                    value:'itemCode'
                    },
                    {
                    text:'Descripción',
                    align:'start',
                    sortable:false,
                    value:'descripcion'
                    },
                    {
                    text:'Cantidad',
                    align:'start',
                    sortable:false,
                    value:'cantidad'
                    },
                ], 

                dataStockDetallado: [
                    {
                        variante:'Físico',
                        itemCode:'Prueba 1',
                        Descripcion:'Producto de prueba 1',
                        cantidad:5
                    },
                    {
                        variante:'Por entregar',
                        itemCode:'Prueba 1',
                        Descripcion:'Producto de prueba 1',
                        cantidad:5
                    },
                    {
                        variante:'Apartado',
                        itemCode:'Prueba 1',
                        descripcion:'Producto de prueba 1',
                        cantidad:5
                    },
                    {
                        variante:'Ordenado',
                        itemCode:'Prueba 1',
                        Descripcion:'Producto de prueba 1',
                        cantidad:5
                    },
                    {
                        variante:'Embarcado',
                        itemCode:'Prueba 1',
                        Descripcion:'Producto de prueba 1',
                        cantidad:5
                    }
                ]
            }
        }, 

        created() {
            this.cargaProductos()
        }, 

        methods: {
            cargaProductos: function() {
                axios.get(process.env.VUE_APP_CONSULTA_STOCK + process.env.VUE_APP_API_KEY).then(response => {   
                    console.log("Carga producto -> ")
                    console.log(response.data)
                   this.dataStock=response.data
                })
            }, 

            abrirPanel(item) {
                console.log("abrir panel")
                this.panelItem=null
                axios.get(process.env.VUE_APP_CONSULTA_STOCK + process.env.VUE_APP_API_KEY + "/" + item.ItemCode).then(response => {
                    console.log("respuesta axios")
                    console.log(response.data)
                    this.panelItem=true
                })
            }
        }
    }
</script>