<template>
    <v-app>
        <v-card height="50vh">
            <v-card-title class="xanadu-fondo white-texto">
                <span class="texto_centrado">Flete nacional</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <Fecha  campo="flete_nacional" @registraFecha="registraFecha"></Fecha>
                    </v-col>
                    <v-col>
                        <TipoTransporteProveedor @TipoTransporteProveedor="registraTipoTransporteProveedor"></TipoTransporteProveedor>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <Monto @monto="registraMonto"></Monto>
                    </v-col>
                </v-row>
                                <span class="maximum_red-texto" v-show="validaFecha">La fecha no puede ir vacía</span>

            </v-card-text>
            <v-card-actions>
                <v-btn
                    @click="btnAceptar"
                    class="maximum_red-fondo white-texto">
                    Aceptar
                </v-btn>
                <v-btn
                    @click="btnCancelar"
                    class="old_rose-fondo space-cadet-texto">
                    Cancelar
                </v-btn>

            </v-card-actions>
        </v-card>

        <TablaConsulta
            :recargaTabla="recargaTabla"
            cabecera="Fecha,Tipo transporte proveedor, Monto"
            criterio="flete_nacional_fecha,flete_nacional_tipo_transporte_proveedor,flete_nacional_monto">
        </TablaConsulta>
    </v-app>
</template>


<script>
import Fecha from '@/components/comex/Elementos/Fecha.vue'
import Divisa from '@/components/comex/Elementos/Divisa.vue'
import Monto from '@/components/comex/Elementos/Monto.vue'
import TipoTransporteProveedor from './Elementos/TipoTransporteProveedor.vue'
import TablaConsulta from '@/components/comex/Elementos/TablaConsulta.vue'


export default {
    name:'fleteNacional',

    components:{Fecha,Divisa,Monto,TipoTransporteProveedor, TablaConsulta},

    props:['modo'],

    data() {
        return {
            validaFecha:false,
            recargaTabla:0,
            ObjFleteNacional: {
                fecha:'',
                tipo_transporte_proveedor:'',
                monto:''
            }

        }
    },

    methods: {
        btnAceptar: function() {
             if (this.ObjFleteNacional.fecha.length==0) {
                this.validaFecha=true
            } else {
                this.recargaTabla++
                this.$emit('objFleteNacional',this.ObjFleteNacional)

            }
        },

        btnCancelar: function() {
            this.validaFecha=false
            this.$emit('objFleteNacional', null)
        },

         registraFecha(valor)
        {
            this.ObjFleteNacional.fecha=valor
        },

        capturaDivisa(valor)
        {
            this.ObjFleteNacional.divisa=valor
        },

         registraTipoTransporteProveedor(valor)
        {
            this.ObjFleteNacional.tipo_transporte_proveedor=valor

        },

         registraMonto(valor)
        {
            this.ObjFleteNacional.monto=valor
        }
    }
}
</script>