var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"fondo_aplicacion h-100 w-100"},[_c('v-row',[_c('v-col',{staticClass:"col-lg-2 col-md-2 col-sm-0"},[_c('Menu')],1),_c('v-col',{staticClass:"col-lg-10 col-md-2 col-sm-12"},[_c('v-row',[_c('v-row',[_c('v-col',{staticClass:"col-lg-12 col-md-12 col-sm-12 ma-5 pr-5"},[_c('v-card',{attrs:{"elevation":"10"}},[_c('v-card-title',[_c('v-icon',{staticClass:"iconoSuperior mr-5"},[_vm._v(" mdi-package-variant-closed ")]),_vm._v(" Stock disponible ")],1)],1)],1)],1),_c('v-col',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('v-card',{staticClass:"white-fondo jet-texto",attrs:{"elevation":"5"}},[_c('v-card-title',[_vm._v(" Catálogo de productos ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.cabecerasStock,"search":_vm.itemCode,"items":_vm.dataStock,"no-data-text":"No existen datos","disable-pagination":"","footer-props":{
                                      showFirstLastage:true,
                                      firstIcon: 'mdi-arrow-collapse-left',
                                      lastIcon:'mdi-arrow-collapse-right',
                                      prevIcon:'mdi-minus',
                                      nextIcon:'mdi-plus',
                                      itemsPerPageText:'Productos a mostrar',
                                      pageText:''
                                  }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar por itemCode o nombre del producto"},model:{value:(_vm.itemCode),callback:function ($$v) {_vm.itemCode=$$v},expression:"itemCode"}})]},proxy:true},{key:"item.acciones",fn:function({item}){return [_c('v-btn',{staticClass:"maximum_red-fondo white-texto",attrs:{"elevation":"2","icon":""},on:{"click":function($event){return _vm.abrirPanel(item)}}},[_c('v-icon',{staticClass:"maximum-red-texto"},[_vm._v(" mdi-chevron-down ")])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }