<template>
    <div class="appHome white-fondo">
        <Header></Header>
        <H1>Bienvenido</H1>

        <v-container>
            <v-btn class="maximum_red-fondo white-texto" @click="formularioComex">
                Ingresar nuevos datos
            </v-btn>
        </v-container>
        <hr />
        <v-container>
            <v-card v-show="avisoParametros">
            <v-card-title class="maximum_red-texto">
                Aviso de información faltante
            </v-card-title>
            <v-card-text>
                Los siguientes parámetros no registran información en la base de datos. Se sugiere ingresar a la brevedad para poder realizar los cálculos de las listas de precio

                <v-list>
                    <v-list-item v-for="parametro in dataParametros">
                        <v-list-item-content>
                            {{parametro.text}}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
            </v-card>
        </v-container>

   <!--     <calendario></calendario> -->

    </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Calendario from '@/components/comex/Calendario.vue'
import axios from 'axios'


export default {
    name:'ComexHome',
    components:{Header,Calendario},

    data() {
        return {
            dataParametros:[],
            avisoParametros:false,
        }
    },


    created() {
        this.revisaParametros()
    },

    methods: {
        formularioComex() {
            this.$router.push('/Comex')
        },

        revisaParametros() {
            let sabanaParametros=''
            let flagParametros=0
            axios.post( process.env.VUE_APP_RUTA_COMEX,{
                accion:'valoresCabecera'
            }).then(response => {

                sabanaParametros=response.data
                sabanaParametros.forEach(function(parametro) {

                    if (parametro.numerico==0) {
                        console.log("parametro")
                        console.log(parametro)
                        this.dataParametros.push(parametro)
                        flagParametros++
                    }
                })

                if (flagParametros>0) {
                        this.avisoParametros=true
                    }

                console.log(this.dataParametros)


            })
        }
    }


}
</script>