<template>
    <v-app class="height:20px !important;background:red !important;">
        <v-card>
            <v-card-title class="xanadu-fondo white-texto">
                <span class="texto_centrado">Ad Valorem</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <fecha campo="ad_valorem" @registraFecha="registraFecha"></fecha>
                    </v-col>
                    <v-col>
                        <origenMarca @OrigenMarca="registraOrigenMarca"></origenMarca>
                    </v-col>
                    <v-col>
                        <Porcentaje @Porcentaje="registraPorcentaje"></Porcentaje>
                    </v-col>
                </v-row>
                <span class="maximum_red-texto" v-show="validaFecha">La fecha no puede ir vacía</span>

            </v-card-text>
            <v-card-actions>
                <v-btn
                    @click="btnAceptar"
                    class="maximum_red-fondo white-texto">
                    Aceptar
                </v-btn>
                <v-btn
                    @click="btnCancelar"
                    class="old_rose-fondo jet-texto">
                    Cancelar
                </v-btn>

            </v-card-actions>
        </v-card>
        <TablaConsulta
            :recargaTabla="recargaTabla"
            cabecera="Fecha,Origen de la marca,Porcentaje"
            criterio="ad_valorem_fecha,ad_valorem_origen_marca,ad_valorem_porcentaje">
        </TablaConsulta>
    </v-app>
</template>



<script>
import Fecha from '@/components/comex/Elementos/Fecha.vue'
import Porcentaje from '@/components/comex/Elementos/Porcentaje.vue'
import OrigenMarca from '@/components/comex/Elementos/OrigenMarca.vue'
import TablaConsulta from '@/components/comex/Elementos/TablaConsulta.vue'

export default {
    name:'adValorem',

    props:['modo'],

    components:{ Fecha, Porcentaje, Porcentaje, OrigenMarca, TablaConsulta },

    data() {
        return {
            validaFecha:false,
            recargaTabla:0,
            ObjAdValorem: {
                fecha:'',
                origen_marca:'',
                porcentaje:''
                }
        }
    },


    methods: {
        registraFecha(valor)
        {
            this.ObjAdValorem.fecha=valor
        },

        registraPorcentaje(valor)
        {
            this.ObjAdValorem.porcentaje=valor
        },

        registraOrigenMarca(valor)
        {
            this.ObjAdValorem.origen_marca=valor
        },

        btnAceptar: function() {
            if (this.ObjAdValorem.fecha.length==0) {
                this.validaFecha=true
            } else {
                this.recargaTabla++;
                this.$emit('objAdValorem',this.ObjAdValorem)
            }

        },

        btnCancelar: function() {
            this.validaFecha=false
            this.$emit('objAdValorem',null)
        }, 

    }
}
</script>