<template>
    <v-app>
        
        <v-row>
            <v-col class="col-lg-2">
                <Menu></Menu>
            </v-col>
            <v-col class="col-lg-10">
                <HeaderProducto></HeaderProducto>
                <v-container fluid class="black-coral white-texto">
                    <v-tabs
                            v-model="tab">
                        <v-tabs-slider class="old_rose-fondo"></v-tabs-slider>

                        <v-tab key="archivo" href="#archivo" class="jet-texto">
                            Actualizar mediante archivo
                        </v-tab>
                        <!--
                        <v-tab key="sap" href="#sap">
                            Actualizar desde SAP
                        </v-tab>
                        <v-tab key="revisar" href="#revisar">
                            Revisar listas de precios
                        </v-tab>
                        -->
                    </v-tabs>


                    <template>
                        <v-tabs-items v-model="tab" style="background:red !important">
                            <v-tab-item id="archivo" value="archivo">
                                <CargaExcel></CargaExcel>
                            </v-tab-item>

                            <v-tab-item id="sap" value="sap">
                                <h2>Sap</h2>
                            </v-tab-item>

                            <v-tab-item id="revisar" value="revisar">
                                <h2>Revisar precios</h2>
                            </v-tab-item>
                    </v-tabs-items>
                    </template>
                </v-container>
            </v-col>
        </v-row>
        
    </v-app>
</template>

<script>
import HeaderProducto from '@/components/globales/headers/HeaderProducto.vue'
import CargaExcel from '@/components/producto/CargaExcel.vue'
import Menu from '@/components/Menu.vue'

export default {
    name:'Producto',

    components:{ HeaderProducto, CargaExcel, HeaderProducto, Menu },

    data () {
        return {
            tab:'archivo'
        }
    }
}

</script>