<template>
    <div class="app-header gradiente">
        
    </div>
</template>
<style>
.cabecera {
    height:50px !important;
    margin-top:15px !important;
}



.imagen_fondo {
    height:30px;
    width:120px;
    margin-left:15px;
    margin-bottom:15px;
}

.texto_derecha {
    margin-left:45%;
}
</style>
<script>
export default {
    name:'HeaderLogin'
}
</script>