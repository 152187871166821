<template>
    <div>
        <v-select
            v-model="TipoTransporteProveedor"
            :items="dataTransporteProveedor"
            item-text="name"
            item-value="code"
            v-on:change="mandaTipoTransporteProveedor"
            label="Tipo Transporte Proveedor">
        </v-select>

    </div>
</template>
<script>
import axios from 'axios'
export default
{
    name:'TipoTransporteProveedor',

    data() {
        return {
            TipoTransporteProveedor:'',
            dataTransporteProveedor:[
                {
                    code:'',
                    name:''
                }
            ]
        }
    },

    created(){
        this.consultaTipoTransporteProveedor()
    },

    methods: {
        mandaTipoTransporteProveedor() {
            this.$emit('TipoTransporteProveedor',this.TipoTransporteProveedor)
        },

        async consultaTipoTransporteProveedor() {
            await axios.post(
                process.env.VUE_APP_RUTA_CONSULTAS_SAP,
                {
                    method:"POST",
                    accion:"consultaTPP"
                }).then(response => {
                    console.log(response.data)
                    this.dataTransporteProveedor=response.data
                })
        }
    }
}

</script>